import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { TFF } from '@tff/types';
import StepConnector from '@mui/material/StepConnector';
import TFFTypography from '../../styled-components/TFFTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      paddingBottom: 8,
      '& .MuiStepper-root': {
        backgroundColor: 'transparent',
      },
    },
    redIcon: {
      color: 'red',
      width: '15px',
    },

    primaryIcon: {
      color: '#737373',
      width: '15px',
    },
    stepLabel: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
      },
    },
  }),
);

const useConnectorStyles = makeStyles({
  stepConnector: (labels: { label: string; currentColor: string }[]) => {
    const styles = {};

    labels.forEach(({ currentColor }, index) => {
      if (index < labels.length - 1) {
        styles[`&:nth-child(${2 * index + 2})`] = { color: currentColor };
      }
    });

    return styles;
  },
  stepConnectorLine: {
    borderColor: '#737373',
  },
});

interface IFlightTrackProps {
  journey: TFF.Journey;
  airports: TFF.IAirport[];
}

const HorizontalFlightTrack: React.FC<IFlightTrackProps> = ({ journey, airports }) => {
  const classes = useStyles();
  const theme = useTheme();
  const segments: TFF.Segment[] = journey.Segments;
  const firstSegment: TFF.Segment | undefined = segments[0];
  const lastSegment = segments[segments.length - 1];

  const getLabels = () => {
    const labels = segments.map((s, index) => ({
      label: s.Origin,
      currentColor:
        (s.Replacements && s.Replacements?.length > 0) || s.State === 'UN' ? 'red' : theme.palette.primary.main,
      icon:
        index === 0 ? (
          <span className="icon medium aircraft-up " data-theme="tui-light" style={{ color: '#1A115A' }} />
        ) : null,
    }));

    labels.push({
      label: lastSegment.Destination,
      currentColor:
        (lastSegment.Replacements && lastSegment.Replacements?.length > 0) || lastSegment.State === 'UN'
          ? 'red'
          : theme.palette.primary.main,
      icon: <span className="icon medium aircraft-down " data-theme="tui-light" style={{ color: '#1A115A' }} />,
    });

    return labels;
  };

  const labels = getLabels();

  const connectorClasses = useConnectorStyles(labels);

  return (
    <div className={classes.root} style={{ flexGrow: 0.7 }}>
      <Stepper
        activeStep={-1}
        connector={
          <StepConnector
            classes={{
              root: connectorClasses.stepConnector,
              line: connectorClasses.stepConnectorLine,
            }}
            className="tff-flight-horizontal-line"
          />
        }
        style={{ padding: 0, alignItems: 'flex-end' }}
      >
        {labels.map(({ label, icon, currentColor }, index) => (
          <Step key={index}>
            <StepLabel
              StepIconProps={{
                icon: '',
                classes: { root: currentColor === 'red' ? classes.redIcon : classes.primaryIcon },
                style: { width: '10px', height: '10px' },
              }}
              className={classes.stepLabel}
            >
              {index === 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '16px',
                  }}
                >
                  <TFFTypography
                    text={`${airports?.find(item => item.id === firstSegment.Origin)?.nameDE} to ${
                      airports?.find(item => item.id === lastSegment.Destination)?.nameDE
                    }`}
                    fontWeight="bold"
                    color="color-theme-variant"
                    modifier="nowrap"
                  />
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '2px' }}>
                {icon && icon}
                <TFFTypography text={label} color="color-functional-neutral-600" />
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default HorizontalFlightTrack;
