import { Chip, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useMemo } from 'react';
import { BaggageIcon } from '../icons';
import { useIntl } from 'react-intl';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { TFF } from '@tff/types';
import StepConnector from '@mui/material/StepConnector';
import CustomDialog from '../Dialogs';
import TFFIcon from '../../styled-components/TFFIcon';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFBadge from '../../styled-components/TFFBadge';

interface props {
  orderDetails: TFF.OrderDetails;
  tffSegment: TFF.Segment;
  isNewOffer: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      marginBottom: 30,
      marginLeft: 38,
    },
    flexStart: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '20px',
      gap: '4px',
    },
    root: {
      width: '50%',
      padding: 0,
      '& .MuiStepper-root': {
        backgroundColor: 'transparent',
      },
    },
    redIcon: {
      color: 'red',
      width: '15px',
    },

    primaryIcon: {
      color: '#737373',
      width: '10px',
      height: '10px',
    },
    stepLabel: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
      },
    },
    redLine: {
      borderColor: 'red',
    },

    primaryLine: {
      borderColor: '#737373',
    },
  }),
);

const FlightSegment: React.FC<props> = ({ tffSegment, orderDetails, isNewOffer }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [openSegmentDialog, setOpenSegmentDialog] = React.useState<boolean>(false);

  const ancillaries: TFF.Ancillary[] = orderDetails.Ancillaries
    ? Object.values(orderDetails.Ancillaries).filter(a => a.JourneyOnd === tffSegment.JourneyOnd)
    : [];
  const journey: TFF.Journey | undefined = orderDetails.Journeys.find(j => j.Ond === tffSegment.JourneyOnd);

  const dcki = useMemo(() => {
    if (ancillaries.length === 0) return null;
    const dcki = ancillaries.find(a => a.Type === 'DCKI');
    if (!dcki) return null;
    return 'Web Check-In';
  }, [ancillaries]);

  const baggage = useMemo(() => {
    if (ancillaries.length === 0) return null;
    const baggages = ancillaries.filter(a => a.Type.startsWith('BI'));
    if (!baggages) return null;
    const luggageValues: number[] = [];
    baggages.forEach(l => {
      if (l.Type.startsWith('BI')) {
        luggageValues.push(+l.Type.substring(2));
      }
    });
    return [...new Set(luggageValues)];
  }, [ancillaries]);

  const cabineclass = useMemo(() => {
    if (!journey) return null;
    return intl.formatMessage({ id: `cabinClass.${tffSegment.CabinClass ?? journey.CabinClass}` });
  }, [orderDetails]);

  const fareBasis = useMemo(() => {
    if (!journey || !journey.FareBasis) return <></>;
    return 'Fare Base: ' + journey.FareBasis;
  }, [orderDetails]);

  const fareFamily = useMemo(() => {
    if (!journey?.FareFamily || journey?.FareFamily === 'Unknown') return <></>;
    return 'Fare Family: ' + journey.FareFamily;
  }, [orderDetails]);

  const renderSimpleFlightTrack = (segment: TFF.Segment) => {
    return (
      <div className={classes.root}>
        <Stepper
          activeStep={-1}
          connector={
            <StepConnector
              classes={{
                root: classes.primaryLine,
                line: segment.State === 'UN' ? classes.redLine : classes.primaryLine,
              }}
            />
          }
          style={{ padding: 0 }}
        >
          <Step>
            <StepLabel
              StepIconProps={{
                icon: '',
                classes: { root: segment.State === 'UN' ? classes.redIcon : classes.primaryIcon },
              }}
              className={classes.stepLabel}
              style={{ marginTop: '-45px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <TFFIcon iconName="aircraft-up" color="color-theme-variant" />
                <TFFTypography text={segment.Origin} color="color-functional-neutral-600" />
              </div>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              StepIconProps={{
                icon: '',
                classes: { root: segment.State === 'UN' ? classes.redIcon : classes.primaryIcon },
              }}
              className={classes.stepLabel}
              style={{ marginTop: '-45px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <TFFIcon iconName="aircraft-down" color="color-theme-variant" />
                <TFFTypography text={segment.Destination} color="color-functional-neutral-600" />
              </div>
            </StepLabel>
          </Step>
        </Stepper>
      </div>
    );
  };

  const handleOpenSegmentHistory = () => {
    setOpenSegmentDialog(true);
  };

  const renderHistorySegment = (segment: TFF.Segment) => {
    return (
      <div className={classes.flexCenter} style={{ marginTop: 20, alignItems: 'center' }}>
        <div className={classes.flexColumn}>
          <Typography component={'span'} color="primary" variant="subtitle1" style={{ lineHeight: 1 }}>
            <strong>
              {segment.MarketingCarrier} {segment.FlightNumber}
            </strong>
          </Typography>
          <Typography component={'span'} color="primary" variant="subtitle1" style={{ lineHeight: 1 }}>
            <strong>
              {new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(
                new Date(segment.DepartureTime),
              )}
            </strong>
          </Typography>
        </div>

        {renderSimpleFlightTrack(segment)}
        <Typography component={'span'} color="primary" variant="subtitle1" style={{ lineHeight: 1 }}>
          <strong>
            {new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(
              new Date(segment.ArrivalTime),
            )}
          </strong>
        </Typography>
        <div style={{ width: '150px' }}>
          {dcki && (
            <div className={classes.flexStart} style={{ alignItems: 'center', padding: '0px' }}>
              <Typography component={'span'} color="primary" variant="subtitle1">
                <span
                  style={{ fontSize: '20px', marginRight: '5px' }}
                  className="tui-icon tui-icon--device-smartphone"
                />
                {dcki}
              </Typography>
            </div>
          )}
          {baggage &&
            baggage.map(l => (
              <div className={classes.flexStart} style={{ alignItems: 'center', padding: '0px' }}>
                <BaggageIcon />
                <Typography
                  component={'span'}
                  color="primary"
                  variant="subtitle1"
                  key={l}
                  style={{ marginLeft: '5px' }}
                >
                  {`1 ${intl.formatMessage({ id: 'flightSegment.luggage' })} ${l}
              ${intl.formatMessage({ id: 'flightSegment.kg' })}`}
                </Typography>
              </div>
            ))}
          <div className={classes.flexStart} style={{ padding: '0px' }}>
            <Typography component={'span'} color="primary" variant="subtitle1">
              <span style={{ fontSize: '20px', marginRight: '5px' }} className="tui-icon tui-icon--seat" />
              {`${cabineclass} Class`}
            </Typography>
            <Typography component={'span'} color="primary" variant="subtitle1">
              xxx
            </Typography>
            <Typography component={'span'} color="primary" variant="subtitle1">
              {`${intl.formatMessage({ id: 'flightSegment.state' })}: ${segment.State}`}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const State: React.FC = () => {
    if (tffSegment.Replacements && tffSegment.Replacements.length > 0) {
      return (
        <div>
          <Typography component={'span'} color="primary" variant="subtitle1">
            {`${intl.formatMessage({ id: 'flightSegment.state' })}`}
            <Chip label={tffSegment.State} color="primary" />
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <Typography component={'span'} color="primary" variant="subtitle1">
              {`${intl.formatMessage({ id: 'flightSegment.changes' })}`}
            </Typography>
            <div onClick={handleOpenSegmentHistory} style={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
              {tffSegment.Replacements.map(s => (
                <Chip label={s.State} color="secondary" style={{ cursor: 'pointer' }} />
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <TFFTypography
          text={`${intl.formatMessage({ id: 'flightSegment.state' })}:  `}
          color="functional-neutral-900"
        />
        <TFFBadge text={tffSegment.State} label="flight-details-badge" />
      </div>
    );
  };

  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  return (
    <div className={classes.flexCenter} style={{ marginTop: 20, alignItems: 'center' }}>
      <div className={classes.flexColumn}>
        <TFFTypography
          text={`${tffSegment.MarketingCarrier} ${tffSegment.FlightNumber}`}
          fontWeight="bold"
          color="color-theme-variant"
        />
        <TFFTypography
          text={`${new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(
            new Date(tffSegment.DepartureTime),
          )}`}
          color="color-functional-neutral-900"
        />
      </div>
      {renderSimpleFlightTrack(tffSegment)}
      <TFFTypography
        text={`${new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(
          new Date(tffSegment.ArrivalTime),
        )}`}
        color="color-functional-neutral-900"
      />
      {!isNewOffer && (
        <div style={{ width: '150px' }}>
          {dcki && (
            <div className={classes.flexStart} style={{ alignItems: 'center', padding: '0px' }}>
              <Typography component={'span'} color="primary" variant="subtitle1">
                <span
                  style={{ fontSize: '20px', marginRight: '5px' }}
                  className="tui-icon tui-icon--device-smartphone"
                />
                {dcki}
              </Typography>
            </div>
          )}
          {baggage &&
            baggage.map(l => (
              <div className={classes.flexStart} style={{ alignItems: 'center', padding: '0px' }}>
                <BaggageIcon />
                <Typography
                  component={'span'}
                  color="primary"
                  variant="subtitle1"
                  key={l}
                  style={{ marginLeft: '5px' }}
                >
                  {`1 ${intl.formatMessage({ id: 'flightSegment.luggage' })} ${l}
                ${intl.formatMessage({ id: 'flightSegment.kg' })}`}
                </Typography>
              </div>
            ))}
          <div className={classes.flexStart} style={{ padding: '0px' }}>
            <TFFTypography
              text={`${capitalizeFirstLetter(tffSegment.CabinClassName)} Class`}
              color="color-functional-neutral-900"
              modifier="nowrap"
            />
            <TFFTypography text={fareBasis} color="color-functional-neutral-900" modifier="nowrap" />
            <TFFTypography text={fareFamily} color="color-functional-neutral-900" modifier="nowrap" />
            <State />
          </div>
        </div>
      )}
      {openSegmentDialog && tffSegment.Replacements && (
        <CustomDialog
          onCancel={() => setOpenSegmentDialog(false)}
          confirmButton={false}
          initialOpen={openSegmentDialog}
          width="md"
        >
          {tffSegment.Replacements.map(s => renderHistorySegment(s))}
        </CustomDialog>
      )}
    </div>
  );
};
export default FlightSegment;
