import React from 'react';
import { useIntl } from 'react-intl';

import { TFF } from '@tff/types';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import useStyles from './AccordionStyles';
import { CreatePassengerAlias } from './CreatePassengerAlias';
import LuggageTableCell from './LuggageTableCell';

interface props {
  flightOnd: string;
  passengers: TFF.Passenger[];
  ancillaries: TFF.MetaValue[];
  type: string;
  displayTable: boolean;
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
  addSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => void;
  removeSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd) => void;
  findSsr: (paxId: string, ssrDetails: TFF.SsrDetails) => TFF.SsrDetails | undefined;
  updateSectionPrice: (price: number) => void;
  setLuggagePrice: React.Dispatch<React.SetStateAction<{ [flightId: string]: { [passengerId: string]: number } }>>;
  luggagePrice: { [flightId: string]: { [passengerId: string]: number } };
  sectionCount: number;
  setSsrsToAdd: React.Dispatch<React.SetStateAction<{ [paxId: string]: TFF.SsrDetails[] }>>;
}

const AdditionalLuggageTable: React.FC<props> = ({
  updateSectionPrice,
  passengers,
  ancillaries,
  type,
  displayTable,
  ssrsToAdd,
  flightOnd,
  addSsrsToAdd,
  removeSsrsToAdd,
  setLuggagePrice,
  luggagePrice,
  sectionCount,
  setSsrsToAdd,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const passengerLength = passengers.filter(obj => obj.Type !== 'INF').length;
  // sort from lower to bigger via comparing prices
  ancillaries.sort((a, b) => a.price.amount - b.price.amount);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '91px',
        minWidth: '320px',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <Typography
          sx={{
            gap: '8px',
            color: '#1A7EAD',
            fontSize: '18px',
            lineHeight: '19.36px',
            fontWeight: '700',
          }}
        >
          {intl.formatMessage({ id: 'ancillaries.edit.additionalLuggage' }).toUpperCase()}
        </Typography>
      </Box>

      <TableContainer
        sx={{
          width: passengerLength === 1 ? '40%' : passengerLength > 5 ? '100%' : '80%',
          overflow: passengerLength < 5 ? 'visible' : 'auto',
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: '1px solid #737373' }}></TableCell>
              {passengers.map((passenger, index) => {
                if (passenger.Type !== 'INF') {
                  return (
                    <TableCell key={`${index}-${passenger.Id}`} align="center" className={classes.tablePassengers}>
                      {CreatePassengerAlias(passenger.Id)}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {ancillaries.map(ancillary =>
              type === 'BAGGAGE' && ancillary.code.includes('BX') ? (
                <TableRow key={ancillary.code}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      background: '#E2F5FD',
                      width: '280px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '280px',
                      }}
                    >
                      <>
                        <Typography>{ancillary.shortDescription}</Typography>
                        <Typography>
                          {ancillary.price.amount === 0
                            ? ''
                            : displayTable
                            ? `${ancillary.price.amount} €`
                            : `${ancillary.price.amount * 2} €`}
                        </Typography>
                      </>
                    </Box>
                  </TableCell>
                  {passengers.map((passenger, index) => {
                    if (passenger.Type !== 'INF') {
                      return (
                        <LuggageTableCell
                          key={`${ancillary.code}-${passenger.Id}`}
                          ancillary={ancillary}
                          passenger={passenger}
                          updateSectionPrice={updateSectionPrice}
                          displayTable={displayTable}
                          ssrsToAdd={ssrsToAdd}
                          flightOnd={flightOnd}
                          addSsrsToAdd={addSsrsToAdd}
                          removeSsrsToAdd={removeSsrsToAdd}
                          setLuggagePrice={setLuggagePrice}
                          luggagePrice={luggagePrice}
                          ancillaryArray={ancillaries}
                          sectionCount={sectionCount}
                          setSsrsToAdd={setSsrsToAdd}
                        />
                      );
                    }
                    return null;
                  })}
                </TableRow>
              ) : null,
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdditionalLuggageTable;
