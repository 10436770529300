import { Box, Button } from '@mui/material';
import React, { SetStateAction } from 'react';
import { useStyles } from './use-styles';
import { SelectedAmendBookings } from './RebookFlightCard';
import { useRebook } from '../RebookContext';

interface props {
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  selectedAmendBookings: SelectedAmendBookings;
  handleAmendBooking: () => void;
  toggleDisplay: (value: boolean) => void;
  resetSearchedDirectionCount: () => void;
}

const ResultPageFooter: React.FC<props> = ({
  rebookCloser,
  selectedAmendBookings,
  handleAmendBooking,
  toggleDisplay,
  resetSearchedDirectionCount,
}) => {
  const classes = useStyles();

  const { searchedDirectionCount } = useRebook();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          marginTop: '32px',
          padding: '0',
        }}
      >
        <Button
          color="info"
          style={{
            backgroundColor: '#F4F4F7',
            color: '#09295D',
            borderColor: '#09295D',
            border: '1px solid',
            height: '100%',
          }}
          onClick={() => rebookCloser(false)}
          className={classes.footerButton}
        >
          CANCEL
        </Button>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <Button
            color="info"
            style={{
              backgroundColor: '#F4F4F7',
              color: '#09295D',
              borderColor: '#09295D',
              border: '1px solid',
              height: '100%',
            }}
            onClick={() => {
              toggleDisplay(false);
              resetSearchedDirectionCount();
            }}
            className={classes.footerButton}
          >
            NEW SEARCH
          </Button>
          <Button
            color="info"
            style={{
              backgroundColor:
                selectedAmendBookings.combiHashCodes.length / 2 !== searchedDirectionCount ? 'grey' : '#092A5E',
              color: 'white',
              paddingLeft: 16,
              paddingRight: 16,
              height: '40px',
            }}
            onClick={() => handleAmendBooking()}
            className={classes.footerButton}
            disabled={selectedAmendBookings.combiHashCodes.length / 2 !== searchedDirectionCount ? true : false}
          >
            ACCEPT
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ResultPageFooter;
