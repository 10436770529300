import * as React from 'react';

interface TFFTabProps {
  tabName: string;
  isActive?: boolean;
  extraStyles?: React.CSSProperties;
  extraClassName?: string;
  handleClick?: () => void;
  disabled?: boolean;
}

export const TFFTab: React.FC<TFFTabProps> = ({
  tabName,
  isActive = false,
  extraStyles,
  extraClassName,
  handleClick,
  disabled = false,
}) => {
  return (
    <button
      role="tab"
      className={`tab ${extraClassName}`}
      aria-selected={isActive}
      onClick={handleClick || undefined}
      disabled={disabled}
      style={extraStyles}
    >
      {tabName}
    </button>
  );
};

export default TFFTab;
