import React from 'react';
import AlternativeFlightPerJourney from './AlternativeFlightPerJourney';
import { AmendJourney } from '@tff/types/TFF';

interface AlternativeFlightCardProps {
  outboundJourney: AmendJourney;
  inboundJourney?: AmendJourney;
}

const AlternativeFlightCard: React.FC<AlternativeFlightCardProps> = ({ outboundJourney, inboundJourney }) => {
  return (
    <div className="tff-alternative-flights-card">
      <div style={{ width: '50%', borderRight: '1px solid var(--color-functional-neutral-400)' }}>
        <AlternativeFlightPerJourney journey={outboundJourney} isOutbound />
        {inboundJourney && <AlternativeFlightPerJourney journey={inboundJourney} />}
      </div>
    </div>
  );
};

export default AlternativeFlightCard;
