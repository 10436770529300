import { TFF } from '@tff/types';

interface AncillariesAccordion {
  type: string;
  ancillaries: TFF.MetaValue[];
}

/* 
  adds mock ssr object "BA0" to accordion data
  and
  create additional object with type "HAND_LUGGAGE
*/

export const updateGroupedRows = (prevRows: AncillariesAccordion[], intl): AncillariesAccordion[] => {
  if (prevRows.length < 2) {
    return prevRows;
  }

  const newRows = [...prevRows];

  const handBags: TFF.MetaValue[] = getHandLuggageElements(
    newRows.filter((i: AncillariesAccordion) => i.type === 'BAGGAGE'),
  );

  let baggageRow = newRows.find((element: AncillariesAccordion) => element.type === 'BAGGAGE')!;
  const additionalBaggageRow = newRows.find((element: AncillariesAccordion) => element.type === 'ADDITIONAL_BAGGAGE')!;

  const baggageExcludingHbag = baggageRow.ancillaries.filter((ancillary: TFF.MetaValue) => ancillary.code !== 'HBAG');

  const newBaggageAncillaries = [
    ...baggageExcludingHbag,
    ...additionalBaggageRow.ancillaries,
    {
      code: 'BA0',
      type: 'BAGGAGE',
      shortDescription: intl.formatMessage({ id: 'ancillaries.edit.handLuggageOnly' }),
      price: {
        amount: 0,
        currency: '',
        regularAmount: 0,
      },
      airlineSsrCode: 'BA0',
      locale: '',
      restriction: { actualCapacityLeft: 99 },
    },
  ];

  // Baggage row should contain baggage + additional baggage
  baggageRow.type = 'BAGGAGE';
  baggageRow.ancillaries = newBaggageAncillaries;

  // Since Additional Baggage is already in Check in luggage, we can remove this group
  const additionalBaggageIndex = newRows.indexOf(additionalBaggageRow);
  newRows.splice(additionalBaggageIndex, 1);

  //Add hand luggage as new type
  newRows.push({
    type: 'HAND_LUGGAGE',
    ancillaries: handBags,
  });

  return newRows;
};

const getHandLuggageElements = (possibleTypes: AncillariesAccordion[]) => {
  const hbagAncillaries: TFF.MetaValue[] = [];

  for (let i = 0; i < possibleTypes.length; i += 1) {
    const handLuggage = possibleTypes[i].ancillaries.find(ancillary => ancillary.code === 'HBAG');
    if (handLuggage) {
      hbagAncillaries.push(handLuggage);
    }
  }

  return hbagAncillaries;
};
