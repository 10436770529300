import * as React from 'react';
import { SetStateAction, useEffect } from 'react';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import TFFTypography from '../../../../styled-components/TFFTypography';
import FlightStopsPath from '../FlightStopsPath';
import { NdcFlightDetails } from '../NdcFlightDetails';
import AlternativeFlightCards from './AlternativeFlightCards';
import { AmendBookingResponse } from '@tff/types/TFF/amend-booking-service';
import TFFAccordion from '../../../../styled-components/TFFAccordion';
import { AlternativeFlightsFooter } from './AlternativeFlightsFooter';

interface props {
  journeys: TFF.Journey[];
  amendResponse?: AmendBookingResponse;
  setRebookStep: (value: 'Search' | 'Alternatives') => void;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
}

const AlternativeFlightsPage: React.FC<props> = ({ journeys, amendResponse, setRebookStep, rebookCloser }) => {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const firstJourney = journeys[0];
  const secondJourney = journeys[journeys.length - 1];

  const hasSecondJourney = journeys.length > 1;

  const capitalizeField = value => {
    const unformattedName = value;
    const lowerCase = unformattedName.toLowerCase();
    return unformattedName.charAt(0).toUpperCase() + lowerCase.slice(1);
  };

  const rebookTitleSplit = intl.formatMessage({ id: 'rebook.Original' }).split(' ');

  return (
    <>
      <div className="tff-original-booking">
        <div>
          <div style={{ display: 'flex', marginBottom: '16px', padding: '16px 16px 0 16px' }}>
            <div>
              <TFFTypography
                text={rebookTitleSplit[0]}
                fontWeight="bold"
                color="color-theme-variant"
                modifier="uppercase"
              />
              <TFFTypography
                text={rebookTitleSplit[1]}
                fontWeight="bold"
                color="color-theme-variant"
                modifier="uppercase"
              />
            </div>
            <div className="tff-inline-paths">
              <FlightStopsPath
                journey={firstJourney}
                capitalizedCabinClass={capitalizeField(firstJourney.CabinClass)}
                icon="aircraft-right"
              />
              {hasSecondJourney && (
                <FlightStopsPath
                  journey={secondJourney}
                  capitalizedCabinClass={capitalizeField(secondJourney.CabinClass)}
                  icon="aircraft-left"
                />
              )}
            </div>
          </div>
          <TFFAccordion
            text={intl.formatMessage({ id: 'pages.flightDetails.flightDetails' })}
            details={
              <NdcFlightDetails
                firstJourney={firstJourney}
                secondJourney={hasSecondJourney ? secondJourney : undefined}
                outboundCabinClass={capitalizeField(firstJourney.CabinClass)}
                inboundCabinClass={capitalizeField(secondJourney.CabinClass)}
              />
            }
          />
        </div>
      </div>
      <AlternativeFlightCards amendResponse={amendResponse} />
      <AlternativeFlightsFooter setRebookStep={setRebookStep} rebookCloser={rebookCloser} />
    </>
  );
};

export default AlternativeFlightsPage;
