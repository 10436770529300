import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TFFTypography from '../../styled-components/TFFTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flightDate: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
  }),
);

interface FlightDateAndTimeProps {
  day: string;
  time: string;
  isOutbound?: boolean;
}

const FlightDateAndTime: React.FC<FlightDateAndTimeProps> = ({ day, time, isOutbound = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.flightDate}>
      <TFFTypography text={day} color="color-functional-neutral-900" />
      <strong className={classes.bullet}>•</strong>
      <TFFTypography text={time} color="color-functional-neutral-900" fontWeight="bold" />
    </div>
  );
};

export default FlightDateAndTime;
