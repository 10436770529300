import * as React from 'react';
import TFFTypography from '../../styled-components/TFFTypography';

interface HeaderInfoDataProps {
  title: string;
  value: any;
  isHighlighted?: boolean;
  isShrunk?: boolean;
}

const HeaderInfoData: React.FC<HeaderInfoDataProps> = ({ title, value, isHighlighted, isShrunk }) => {
  return (
    <div className="tff-bookingHeadingContainer">
      <TFFTypography
        text={title}
        modifier="uppercase"
        color={isShrunk ? 'color-functional-neutral-600' : 'color-functional-neutral-100'}
        size="small"
      />
      <TFFTypography
        text={value}
        color={isShrunk ? 'color-functional-neutral-900' : 'color-functional-neutral-000'}
        size={isHighlighted ? 'large' : 'medium'}
        fontWeight={isHighlighted ? 'bold' : 'normal'}
      />
    </div>
  );
};

export default HeaderInfoData;
