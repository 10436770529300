import React from 'react';
import { AmendJourney } from '@tff/types/TFF';
import { NdcFlightPath } from '../NdcFlightPath';

interface AlternativeFlightDetailsProps {
  journey: AmendJourney;
}

const AlternativeFlightDetails: React.FC<AlternativeFlightDetailsProps> = ({ journey }) => {
  return (
    <div style={{ padding: '16px' }}>
      {journey?.Segments.map((i, index) => (
        <NdcFlightPath
          segment={i}
          index={index}
          key={index}
          stops={journey.Stops}
          /* @ts-ignore */
          stopDuration={journey.StayDuration}
          cabinClass={''}
        />
      ))}
    </div>
  );
};

export default AlternativeFlightDetails;
