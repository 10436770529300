import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { TFF } from '@tff/types';
import FlightSegment from './FlightSegment';
import { useIntl } from 'react-intl';
import TFFAccordion from '../../styled-components/TFFAccordion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flightDetailRoot: {
      margin: '5px 0',
      backgroundColor: 'transparent',
      width: '100%',
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        width: '100%',
        marginBottom: '-20px',
      },
    },
  }),
);

interface FlightDetailsProps {
  segments: TFF.Segment[];
  orderDetails: TFF.OrderDetails;
}

const FlightDetails: React.FC<FlightDetailsProps> = ({ segments, orderDetails }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.flightDetailRoot}>
      <TFFAccordion
        text={intl.formatMessage({ id: 'pages.flightDetails.flightDetails' })}
        variant="primary"
        details={
          <>
            {segments.map((segment: TFF.Segment, index: number) => {
              return (
                <FlightSegment
                  key={index}
                  tffSegment={segment as TFF.Segment}
                  orderDetails={orderDetails}
                  isNewOffer={false}
                />
              );
            })}
          </>
        }
      />
    </div>
  );
};

export default FlightDetails;
