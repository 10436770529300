import React, { JSX } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import TFFIcon from './TFFIcon';

// Note: Right now we are using MuiAccordion to take advantage of the behavior that comes with it, however in the future we should be using TUI Light Theme for this
interface TFFAccordionProps {
  text: string | JSX.Element;
  details: JSX.Element;
  variant?: 'dashed' | 'primary';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&::before': {
        display: 'none',
      },
    },
  }),
);

export const TFFAccordion: React.FC<TFFAccordionProps> = ({ text, details, variant = 'dashed' }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordionRoot}>
      <AccordionSummary
        expandIcon={
          <span style={{ color: 'var(--color-functional-base-600)' }}>
            <TFFIcon iconName="chevron-down" />
          </span>
        }
        aria-controls="original-booking-details-content"
        id="original-booking-details-header"
      >
        <p
          className="text medium link"
          style={{
            color: 'var(--color-functional-base-600)',
            marginLeft: 'auto',
          }}
        >
          {text}
        </p>
      </AccordionSummary>
      <AccordionDetails
        style={
          variant === 'dashed'
            ? {
                borderTop: '1px dashed var(--color-functional-neutral-400)',
              }
            : { backgroundColor: '#F5F5F5', display: 'block', width: '100%', marginBottom: '15px' }
        }
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

export default TFFAccordion;
