import * as React from 'react';
import { TUI_LIGHT } from '../values/style-values';

interface TFFIconProps {
  iconName: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
  color?: string;
}

export const TFFIcon: React.FC<TFFIconProps> = ({ iconName, size = 'medium', color }) => {
  return (
    <span
      className={`icon ${size} ${iconName}`}
      style={color ? { color: `var(--${color})` } : undefined}
      data-theme={TUI_LIGHT}
    ></span>
  );
};

export default TFFIcon;
