import { AmendBookingResponse } from '@tff/types/TFF';
import AlternativeFlightCard from './AlternativeFlightCard';
import React from 'react';
import TFFTypography from '../../../../styled-components/TFFTypography';

interface props {
  amendResponse?: AmendBookingResponse;
}

const AlternativeFlightCards: React.FC<props> = ({ amendResponse }) => {
  //TODO - this is the case where the BE retrieves an failure response, in which case we will have a future ticket to tackle this
  if (!amendResponse) {
    return (
      <div>
        No alternative flights to show for now. Note: this is the case where the BE retrieves an failure response, in
        which case we will have a future ticket to tackle this
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '16px 0' }}>
        <TFFTypography
          text="Alternative Flight Results"
          modifier="uppercase"
          color="color-theme-variant"
          fontWeight="bold"
          size="large"
        />
      </div>
      {Object.keys(amendResponse?.flightAlternatives?.CombinationOffers!).map((combinationOfferKey, index) => {
        const outboundJourneyId = combinationOfferKey.split('-')[0];
        const outboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === outboundJourneyId,
        );

        const inboundJourneyId = combinationOfferKey.split('-')[1];
        const inboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === inboundJourneyId,
        );

        return outboundJourney ? (
          <AlternativeFlightCard key={index} outboundJourney={outboundJourney} inboundJourney={inboundJourney} />
        ) : null;
      })}
    </div>
  );
};

export default AlternativeFlightCards;
