import TFFTypography from '../../../styled-components/TFFTypography';
import { useTypedSelector } from '../../../reducers';
import { weekday } from '../../FlightDetailCard';
import { format } from 'date-fns';

interface props {
  segmentName: string;
  time: string;
}

export const NdcFlightPathAttributes: React.FC<props> = ({ segmentName, time }) => {
  const { locale } = useTypedSelector(({ settings }) => settings);

  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }

    return null;
  };

  return (
    <div style={{ gap: '8px', display: 'flex' }}>
      <TFFTypography text={segmentName} color="color-functional-neutral-900" />
      <TFFTypography text={time.split('T')[1]} color="color-functional-neutral-900" fontWeight="bold" />
      <TFFTypography
        text={`${weekday(time, locale.locale).substring(0, 2)}. ${formatDate(time)}`}
        color="color-functional-neutral-900"
        extraClassName="tff-text-no-wrap"
      />
    </div>
  );
};
