import { TFF } from '@tff/types';
import TFFTypography from '../../../styled-components/TFFTypography';
import { NdcFlightPathAttributes } from './NDCFlightPathAttributes';

interface props {
  segment: TFF.Segment;
  index: number;
  stops: number;
  stopDuration: number;
  cabinClass: string;
}

export const NdcFlightPath: React.FC<props> = ({ segment, index, stops, stopDuration, cabinClass }) => {
  const convertToTimeFormat = num => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours}:${String(minutes).padStart(2, '0')}`;
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div className="tff-circle"></div>
            <div
              style={{
                borderLeft: '1px solid var(--color-functional-neutral-600)',
                height: '36px',
              }}
            />
            <div className="tff-circle"></div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', justifyContent: 'center' }}>
            <NdcFlightPathAttributes segmentName={segment.Origin} time={segment.DepartureTime} />
            <NdcFlightPathAttributes segmentName={segment.Destination} time={segment.ArrivalTime} />
          </div>
        </div>
        <div>
          <TFFTypography
            text={`Flight Number: ${segment.MarketingCarrier}${segment.FlightNumber} - Type: ${segment.Equipment}`}
            color="color-functional-neutral-900"
          />
          <TFFTypography text={`Cabin Class: ${cabinClass}`} color="color-functional-neutral-900" />
        </div>
      </div>
      {stops > 0 && index < stops && (
        <div style={{ padding: '8px 0' }}>
          <hr className="tff-horizontal-line flightDetails" />
          <TFFTypography
            text={`${convertToTimeFormat(stopDuration[index])} hrs stay - ${segment.Destination} Airport`}
            color="color-functional-neutral-900"
          />
          <hr className="tff-horizontal-line flightDetails" />
        </div>
      )}
    </div>
  );
};
