import React from 'react';

type BadgeVariants =
  | 'primary'
  | 'promotion'
  | 'secondary'
  | 'ghost'
  | 'tertiary'
  | 'error'
  | 'success'
  | 'neutral'
  | 'light'
  | 'warning';

interface TFFBadgeProps {
  variant?: BadgeVariants;
  text: string | undefined;
  size?: 'small' | 'medium';
  label: string;
}

export const TFFBadge: React.FC<TFFBadgeProps> = ({ variant = 'primary', text, size = 'small', label }) => {
  return (
    <span className={`badge ${variant} ${size}`} role="status" aria-label={label}>
      {text}
    </span>
  );
};

export default TFFBadge;
