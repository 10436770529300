import * as React from 'react';
import { useMemo } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { format } from 'date-fns';
import { TFF } from '@tff/types';
import { weekday } from '.';
import { useTypedSelector } from '../../reducers';
import HorizontalFlightTrack from './HorizontalFlightTrack';
import FlightDateAndTime from './FlightDateAndTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: '16px',
    },
  }),
);

interface IFlightTrackProps {
  journey: TFF.Journey;
  airports: TFF.IAirport[];
}

const FlightTrack: React.FC<IFlightTrackProps> = ({ journey, airports }) => {
  const classes = useStyles();

  const segments = journey.Segments;

  const firstSegment = segments![0];
  const lastSegment = segments![segments.length - 1];
  const { locale } = useTypedSelector(({ settings }) => settings);

  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }

    return null;
  };

  /**
   * @description Find aggregated lift status, if 'Default' is found it returns an empty string, or if more than one status is found then returns others.
   * @return string
   */
  const liftStatus: JSX.Element = useMemo(() => {
    const resultArr: string[] = [];

    if (journey?.LiftStatusAggregated) {
      journey.LiftStatusAggregated.forEach(s => {
        if (s !== 'Default') {
          resultArr.push(s);
        }
      });
    }
    return resultArr.length ? (
      <div id={'aggregated-lift-status'} className={classes.flexCenter} style={{ marginTop: 0, paddingTop: 0 }}>
        <strong>Lift-status: {resultArr.join(', ')}</strong>
      </div>
    ) : (
      <></>
    );
  }, [journey.LiftStatusAggregated]);

  const departureDay = `${weekday(firstSegment?.DepartureTime, locale.locale).substring(0, 2)}. ${formatDate(
    firstSegment?.DepartureTime,
  )}`;

  const departureTime = firstSegment?.DepartureTime.split('T').pop()?.substring(0, 5) || '';

  const arrivalDay = `${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
    lastSegment?.ArrivalTime,
  )}`;

  const arrivalTime = lastSegment?.ArrivalTime.split('T').pop()?.substring(0, 5) || '';

  return (
    <>
      <div className={classes.flexCenter} style={{ marginTop: 0 }}>
        <FlightDateAndTime day={departureDay} time={departureTime} isOutbound />
        <HorizontalFlightTrack journey={journey} airports={airports} />
        <FlightDateAndTime day={arrivalDay} time={arrivalTime} />
      </div>
      {liftStatus}
    </>
  );
};

export default FlightTrack;
