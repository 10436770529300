import { Alert, AlertTitle, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { de, enUS } from 'date-fns/locale';
import { format, parse } from 'date-fns';

import { AmendTfaAncillaryLambdaRequest, IFee, Journey } from '@tff/types/TFF';

import FlightTrack from './FlightTrack';

import { GasStationIcon } from '../icons';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import { Seat6MComponent } from '../../util';
import { useNavigate } from 'react-router-dom';
import SeatsReservation from '../../util/Seats6M';
import { map6MResults } from '../../util/map6MResults';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { create6MSeatComponentConfig } from '../../util/map6MSeats';
import { useAmendBooking } from '../../hooks/use-amend-booking';
import { AmendBookingRequest } from '@tff/types/TFF/amend-booking-service';
import { PaxData } from '@tff/types/TFF/tfa-amend-ancillary-service';
import CustomDialog from '../Dialogs';
import FlightDetails from './FlightDetails';
import FlightSeatsAndPrice from './FlightSeatsAndPrice';
import TFFTypography from '../../styled-components/TFFTypography';
import TFFIcon from '../../styled-components/TFFIcon';

interface props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  isOutbound: boolean;
  fee?: IFee;
  airports: TFF.IAirport[];
  disableButtons: boolean;
  bookingSource: string;
  recordLocator: string;
  tfmPnr: string;
  midocoOrderNo?: number;
}

export type NewSegment = TFF.Segment & { NewState?: string };

export const weekday = (dateString: string | undefined, siteLang: string): string => {
  let convertedDate = dateString;
  if (!dateString) {
    return '--';
  }

  let parsedDate: Date;
  if (dateString.indexOf('-') > -1) {
    parsedDate = parse(dateString!.substr(0, 10), 'yyyy-MM-dd', new Date());
  } else {
    parsedDate = parse(dateString!.substr(0, 10), 'dd.MM.yyyy', new Date());
  }
  convertedDate = format(parsedDate, 'eeee', {
    locale: siteLang === 'de' ? de : enUS,
  });

  return convertedDate;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      borderColor: '#BABABA',
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      padding: '16px 16px 0 16px',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    flightTrackLine: {
      height: '2px',
      backgroundColor: theme.palette.text.primary,
      width: '60%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightTrackDot: {
      height: '12px',
      width: '12px',
      borderRadius: '6px',
      backgroundColor: theme.palette.text.primary,
    },
  }),
);

const FlightDetailCard: React.FC<props> = ({
  journey,
  orderDetails,
  fee,
  airports,
  bookingSource,
  recordLocator,
  tfmPnr,
  midocoOrderNo,
  isOutbound,
}) => {
  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();
  const [customDialogMsg, setCustomDialogMsg] = useState<string | undefined>(undefined);

  const segments: TFF.Segment[] = journey.Segments;

  const changeView = useMediaQuery('(max-width: 1680px)');

  const classes = useStyles();
  const [toolTip, setToolTip] = useState<string[]>([]);
  const intl = useIntl();

  const [openSeats, setOpenSeats] = useState<boolean>(false);
  const [scope, setScope] = useState<string>('*');

  const navigate = useNavigate();
  const { showLoading, closeLoading } = React.useContext(LoadingContext);

  const firstSegment: TFF.Segment | undefined = segments[0];
  const lastSegment = segments[segments.length - 1];
  const ancillaries: TFF.Ancillary[] | undefined = orderDetails?.Ancillaries
    ? Object.values(orderDetails.Ancillaries)
    : [];

  const identifyState = (journey: Journey): string | undefined => {
    let ret = journey.State;

    journey.Segments.forEach(s => {
      if (s.State === 'UN') {
        ret = 'UN';
      }

      if (ret && s.State === 'TK') {
        ret = 'TK';
      }

      if (!ret && s.State === 'HK') {
        ret = 'HK';
      }
    });

    return ret;
  };

  const techStops = useMemo(() => {
    // Check for any technical stops in the flight
    const techStops = segments.flatMap(segment => (segment.TechStops ? segment.TechStops : []));

    return techStops;
  }, [segments]);

  const formatDate = dateString => {
    return new Intl.DateTimeFormat('de-DE', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(dateString));
  };

  const renderTechStops = () => {
    if (techStops.length === 0) {
      return null;
    }

    return (
      <Alert severity="warning" style={{ margin: '5px' }}>
        <AlertTitle>
          <strong>
            {intl.formatMessage({ id: 'techStop.available' })} <GasStationIcon />
          </strong>
        </AlertTitle>

        {techStops.map(s => (
          <Typography key={s.Airport}>
            {intl.formatMessage({ id: 'techStop.airport' })}: <strong>{s.Airport}</strong> -{' '}
            {intl.formatMessage({ id: 'techStop.from' })} <strong>{formatDate(s.ArrivalTime)}</strong>{' '}
            {intl.formatMessage({ id: 'techStop.until' })} <strong>{formatDate(s.DepartureTime)}</strong>
          </Typography>
        ))}
      </Alert>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <TFFTypography
          text={`${firstSegment?.MarketingCarrier} ${firstSegment?.FlightNumber}`}
          fontWeight="bold"
          color="color-theme-variant"
        />
        <TFFTypography
          text={`(${identifyState(journey)})`}
          color={identifyState(journey) === 'UN' ? 'color-functional-error-600' : 'color-functional-neutral-600'}
        />
      </div>
    );
  };

  useEffect(() => {
    if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'validate') {
      setCustomDialogMsg(
        `To change your seat we will charge you ${amendResponse.bookingSum?.BalanceDue} Euro. Are you sure you want to proceed?`,
      );

      if (amendResponse.tfaAmendResponseDetails?.status === 'BOOKED_PARTIALLY') {
        const message: string = `There is an issue with your seat selection, we can't proceed with the change. Please check your seat selection and try again.
          ${amendResponse.tfaAmendResponseDetails?.issues?.map(i => i.message).join('\n\r')}`;
        setCustomDialogMsg(message);
      }
      /*
      Here we are be able to validate the cost difference between the old and the new seat selection.
      At least we can use the BalancDue from the response to display a confirmation message.
      Also we are be able to validate if the seat reservation will be successful or not!
      */
    } else if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'confirm') {
      navigate(0);
      /*
      Here we are be able to show a confirmation dialog or a success message.
       */
    } else if (amendStatus === 'FAILURE') {
      if (amendResponse?.query.action === 'confirm') {
        /*
      Here we are be able to show a error message
       */
      }
      // setErrorDialog(true);
    }
  }, [amendStatus]);

  const seatsConfig = useMemo(() => create6MSeatComponentConfig([journey], orderDetails), [journey, orderDetails]);

  const handleAmendBooking = (action: 'validate' | 'confirm', paxData: PaxData[], sessionId?: string) => {
    setCustomDialogMsg(undefined);
    const amendBookingRequest: AmendBookingRequest = {
      action: action,
      bookingSource: 'TUI-NSK',
      recordLocator: recordLocator,
      tfmPnr: tfmPnr!,
      paxData: paxData,
      midocoOrderNo: midocoOrderNo,
      sessionId: sessionId,
    };
    setAmendBookingRequest(amendBookingRequest);
  };

  const handleCloseSeatsReservation = (data?: Seat6MComponent) => {
    setOpenSeats(false);
    if (!data) return;
    setScope(prev => (prev === '*' ? '+' : '*'));
    const seatAncillaries = ancillaries.filter((a: TFF.Ancillary) => a.JourneyOnd === journey.Ond && a.Type === 'SEAT');
    const mapData: AmendTfaAncillaryLambdaRequest = map6MResults(data.flights, recordLocator, seatAncillaries);
    handleAmendBooking('validate', mapData.paxData, undefined);
  };

  const JourneyHasChangeState = (): boolean =>
    React.useMemo(() => {
      let res = false;
      if (journey.AdditionalParams && journey.AdditionalParams[`TFF_ALERT:UNCONFIRMED_STATE`]) {
        setToolTip(old => {
          return [...old, journey.AdditionalParams![`TFF_ALERT:UNCONFIRMED_STATE`]];
        });
        res = true;
      }
      return res;
    }, [journey]);

  function handleConfirmSeatSelection() {
    setCustomDialogMsg(undefined);
    handleAmendBooking('confirm', amendResponse!.query!.paxData!, amendResponse!.sessionId!);
  }

  function handleAbordSeatSelection() {
    setCustomDialogMsg(undefined);
  }

  return (
    <>
      {customDialogMsg && (
        <CustomDialog
          initialOpen={true}
          onCancel={() => handleAbordSeatSelection()}
          onConfirm={() => handleConfirmSeatSelection()}
          width="md"
          cancelButtonText={intl.formatMessage({ id: 'abort' })}
          confirmButtonText={intl.formatMessage({ id: 'continue' })}
          closeOnBackdropClick={false}
          title={'Result of adding Seat: ' + amendResponse?.tfaAmendResponseDetails?.status}
          warning
          confirmDisabled={
            !amendResponse?.tfaAmendResponseDetails || amendResponse!.tfaAmendResponseDetails!.status !== 'BOOKED'
          }
        >
          <Typography variant="h6" color="primary">
            {customDialogMsg}
          </Typography>
        </CustomDialog>
      )}
      <div className={classes.root}>
        {JourneyHasChangeState() && (
          <Tooltip
            title={
              <>
                {toolTip.map(tip => (
                  <p key={tip}>{tip}</p>
                ))}
              </>
            }
          >
            <div className={classes.infoIcon}>!</div>
          </Tooltip>
        )}
        <div style={{ display: 'flex', padding: '16px 16px 0 16px' }}>
          <span>
            <TFFIcon iconName={isOutbound ? 'aircraft-right' : 'aircraft-left'} color="color-theme-variant" />
          </span>
          <div style={{ marginLeft: '16px', flexGrow: 1 }}>
            {renderHeader()}
            {journey && <FlightTrack journey={journey} airports={airports} />}
          </div>
        </div>
        <div id="tfa">
          {
            //@ts-ignore
            <tui-tfa-ssr scope="scope_1" locale="de-DE"></tui-tfa-ssr>
          }
        </div>
        {renderTechStops()}
        <FlightDetails segments={segments} orderDetails={orderDetails} />
        <FlightSeatsAndPrice
          ancillaries={ancillaries}
          journey={journey}
          orderDetails={orderDetails}
          bookingSource={bookingSource}
          setOpenSeats={setOpenSeats}
        />
        {openSeats && (
          <div>
            <SeatsReservation
              initialOpen={openSeats}
              onClose={handleCloseSeatsReservation}
              scope={scope}
              configs={seatsConfig}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FlightDetailCard;
