import React from 'react';
import FlightStopsPath from '../FlightStopsPath';
import { AmendJourney } from '@tff/types/TFF';
import TFFAccordion from '../../../../styled-components/TFFAccordion';
import { useIntl } from 'react-intl';
import AlternativeFlightDetails from './AlternativeFlightDetails';

interface props {
  journey: AmendJourney;
  isOutbound?: boolean;
}

const AlternativeFlightPerJourney: React.FC<props> = ({ journey, isOutbound }) => {
  const intl = useIntl();

  return (
    <div style={isOutbound ? { borderBottom: '1px solid #BABABA' } : undefined}>
      <div
        style={{
          padding: '24px 64px 0 24px',
        }}
      >
        <FlightStopsPath journey={journey} capitalizedCabinClass="Class" alignClassToRight />
      </div>
      <TFFAccordion
        text={intl.formatMessage({ id: 'pages.flightDetails.flightDetails' })}
        details={<AlternativeFlightDetails journey={journey} />}
      />
    </div>
  );
};

export default AlternativeFlightPerJourney;
