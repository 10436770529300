import React, { JSX } from 'react';

interface TFFTypographyProps {
  variant?: 'lead' | 'base';
  text: string | JSX.Element;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
  fontWeight?: 'normal' | 'semibold' | 'bold';
  space?: 'nospace' | 'space';
  color?: string;
  modifier?:
    | 'uppercase'
    | 'wrap'
    | 'nowrap'
    | 'wrap-balance'
    | 'break'
    | 'decoration-none'
    | 'decoration-underline'
    | 'line-height-normal'
    | 'line-height-spaced'
    | 'line-height-baseline'
    | 'tabular-nums'
    | 'oldstyle-nums';
  extraClassName?: string;
}

export const TFFTypography: React.FC<TFFTypographyProps> = ({
  variant = 'base',
  text,
  size = 'medium',
  fontWeight = 'normal',
  space = 'nospace',
  color,
  modifier,
  extraClassName,
}) => {
  return (
    <p
      className={`text ${variant} ${size} ${fontWeight} ${space} ${modifier} ${extraClassName}`}
      style={color ? { color: `var(--${color})` } : undefined}
    >
      {text}
    </p>
  );
};

export default TFFTypography;
