import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  Divider,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TFF } from '@tff/types';
import { MissingAncillaries } from './Utils/AncillariesPage/missingAncillaries';
import FlightAncillaryTable from './FlightAncillaryTable';
import AdditionalLuggageTable from './AdditionalLuggageTable';
import useStyles from './AccordionStyles';
import SwitchComponent from './SwitchComponents';
import { TrashIcon } from '../icons/TrashIcon';
import { CreatePassengerAlias } from './CreatePassengerAlias';
import AlertMessage from '../AlertMessage';
import { AncillaryType } from '../../types/ancillaryTypes';
import {
  getSportInitialSsrAvailability,
  isAncillaryIncludedForAllPassengers,
} from './Utils/AncillariesPage/ancillariesAvailability';
import { ValidatedSsrDetails } from '@tff/types/TFF';

interface GroupedAncillaries {
  type: string;
  ancillaries: { outbound: TFF.MetaValue[]; inbound: TFF.MetaValue[] };
  matchedPassengers: {};
}

interface props {
  flightDetails: TFF.FlightDetails;
  groupedRows: any;
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] };
  rebooking?: boolean;
  addSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd, ssrsToAdd, includedSsrs) => void;
  removeSsrsToAdd: (paxId: string, addSsrs: TFF.SsrDetails, setSsrsToAdd) => void;
  findSsr: (paxId: string, ssrDetails: TFF.SsrDetails) => TFF.SsrDetails | undefined;
  resetSsrs: (ssrType: string, setSsrsToAdd, groupedRows, includedSsrs) => void;
  fareFamily?: string[];
  newOnds?: {
    Outbound: string;
    Inbound: string;
  };
  setSsrsToAdd: React.Dispatch<React.SetStateAction<{ [paxId: string]: TFF.SsrDetails[] }>>;
  groupedAncillary: GroupedAncillaries[];
  includedSsrs: {
    [paxId: string]: TFF.SsrDetails[];
  };
  validatedAncillaries?: ValidatedSsrDetails[];
  missingAncillaries: MissingAncillaries;
  isOutboundRebooked: boolean | undefined;
  isInboundRebooked: boolean | undefined;
}

const AncillaryAccordion: React.FC<props> = ({
  findSsr,
  removeSsrsToAdd,
  addSsrsToAdd,
  flightDetails,
  groupedRows,
  ssrsToAdd,
  resetSsrs,
  rebooking,
  fareFamily,
  newOnds,
  setSsrsToAdd,
  groupedAncillary,
  includedSsrs,
  validatedAncillaries,
  missingAncillaries,
  isOutboundRebooked,
  isInboundRebooked,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const passengers = Object.values(flightDetails.OrderDetails.Passengers);
  const outboundFlight = rebooking
    ? (newOnds?.Outbound || '').match(/.{1,3}/g)?.join('-') || ''
    : flightDetails.OrderDetails.Journeys[0]?.Ond || '';

  const returnFlight = rebooking
    ? (newOnds?.Inbound || '').match(/.{1,3}/g)?.join('-') || ''
    : flightDetails.OrderDetails.Journeys[1]?.Ond || '';

  const [displayTable, setDisplayTable] = useState<boolean>(true);
  const [displayIcon, setDisplayIcon] = useState<boolean>(false);
  const [sectionPrice, setSectionPrice] = useState<number>(0);
  const [sectionCount, setSectionCount] = useState<number>(0);
  const [isBaggageAddedOutbound, setIsBaggageAddedOutbound] = useState<boolean>(false);
  const [isBaggageAddedReturn, setIsBaggageAddedReturn] = useState<boolean>(false);
  const [luggagePrice, setLuggagePrice] = useState<{ [flightId: string]: { [passengerId: string]: number } }>({});
  const [outboundSsrAvailability, setOutboundSsrAvailability] = useState(
    getSportInitialSsrAvailability(groupedRows.ancillaries.outbound),
  );
  const [inboundSsrAvailability, setInboundSsrAvailability] = useState(
    getSportInitialSsrAvailability(groupedRows.ancillaries.inbound),
  );
  const [isOutboundAncillariesAlertShown, setIsOutboundAncillariesAlertShown] = useState<boolean>(false);
  const [isInboundAncillariesAlertShown, setIsInboundAncillariesAlertShown] = useState<boolean>(false);

  const outboundFare: string | undefined = fareFamily?.[0];
  const inboundFare: string | undefined = fareFamily?.[1];

  groupedRows.matchedPassengers = {};

  Object.keys(ssrsToAdd).forEach(paxKey => {
    ssrsToAdd[paxKey].forEach(paxItem => {
      const paxItemWithId = { ...paxItem, pax_id: paxKey };

      const ancillaries =
        groupedRows.ancillaries.outbound.length > 0
          ? groupedRows.ancillaries.outbound
          : groupedRows.ancillaries.inbound;

      ancillaries.forEach(ancillary => {
        if (ancillary.code === paxItem.code) {
          if (!groupedRows.matchedPassengers[ancillary.code]) {
            groupedRows.matchedPassengers[ancillary.code] = [];
          }
          groupedRows.matchedPassengers[ancillary.code].push(paxItemWithId);
        }
      });
    });
  });

  const isApplyPossible = matchedPassengers => {
    let allMatched = true;

    if (groupedRows.ancillaries.outbound.length === 0) {
      return (allMatched = false);
    }
    if (groupedRows.ancillaries.inbound.length === 0) {
      return (allMatched = false);
    }

    Object.keys(matchedPassengers).forEach(code => {
      const passengerObjects = matchedPassengers[code];

      passengerObjects.forEach(passenger => {
        const reversedSegmentOnd = passenger?.segmentOnd?.split('-').reverse().join('-') ?? '';

        const match = passengerObjects.find(
          p =>
            p.segmentOnd === reversedSegmentOnd &&
            p.code === passenger.code &&
            p.pax_id === passenger.pax_id &&
            p.amount === passenger.amount,
        );

        if (!match) {
          allMatched = false;
        }
      });
    });
    return allMatched;
  };

  const processSsrsToAdd = (ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] }, removeSsrsToAdd, flightOND) => {
    Object.entries(ssrsToAdd).forEach(([paxID, items]) => {
      items
        .filter(item => item.code.includes('BX'))
        .forEach(item => {
          removeSsrsToAdd(
            paxID,
            {
              segmentOnd: flightOND,
              code: item.code,
              amount: 1,
            },
            setSsrsToAdd,
          );
        });
    });
  };

  const resetPricesForFlight = (flightId: string) => {
    setLuggagePrice(prevState => {
      const flightExists = prevState[flightId];
      if (!flightExists) return prevState; // If the flight doesn't exist, return state unchanged

      // Create a new object with the same keys but all values set to 0
      const resetPassengerPrices = Object.keys(flightExists).reduce((acc, passengerId) => {
        acc[passengerId] = 0;
        return acc;
      }, {} as { [passengerId: string]: number });

      return {
        ...prevState,
        [flightId]: resetPassengerPrices, // Reset all prices for the flight
      };
    });
  };

  const substractLuggage = (luggage: number[]) => {
    Object.values(luggage).map(i => updateSectionPrice(-i));
  };

  useEffect(() => {
    if (!isBaggageAddedOutbound && !isBaggageAddedReturn) {
      setSectionPrice(0);
    }
    if (!isBaggageAddedOutbound && luggagePrice[outboundFlight]) {
      processSsrsToAdd(ssrsToAdd, removeSsrsToAdd, outboundFlight);
      substractLuggage(Object.values(luggagePrice[outboundFlight]));
      resetPricesForFlight(outboundFlight);
    }
    if (!isBaggageAddedReturn && luggagePrice[returnFlight]) {
      processSsrsToAdd(ssrsToAdd, removeSsrsToAdd, returnFlight);
      substractLuggage(Object.values(luggagePrice[returnFlight]));
      resetPricesForFlight(returnFlight);
    }
  }, [isBaggageAddedOutbound, isBaggageAddedReturn]);

  const updateSectionPrice = (price: number) => {
    setSectionPrice(prevPrice => prevPrice + price);
  };

  useEffect(() => {
    const prevPrice = sectionPrice;
    if (displayTable) {
      setSectionPrice(prevPrice);
    }
  }, [displayTable]);

  useEffect(() => {
    if (groupedRows.type === 'BAGGAGE') {
      const hasBaggageForFlight = (flightSegment: string) =>
        Object.values(ssrsToAdd).some(ssrs =>
          ssrs.some(ssr => ['BA20', 'BA25', 'BA32'].includes(ssr.code) && ssr.segmentOnd === flightSegment),
        );

      const outboundHasBaggage = hasBaggageForFlight(outboundFlight);
      const returnHasBaggage = hasBaggageForFlight(returnFlight);

      setIsBaggageAddedOutbound(outboundHasBaggage);
      setIsBaggageAddedReturn(returnHasBaggage);
    }
  }, [ssrsToAdd]);

  useEffect(() => {
    if (sectionPrice < 0) {
      setSectionPrice(0);
    }
  }, [sectionPrice]);

  useEffect(() => {
    const shouldShowOutboundAlert =
      groupedRows.type === AncillaryType.Sports &&
      (missingAncillaries.outbound.length > 0 || isAnyAncillaryUnavailable(outboundSsrAvailability, outboundFlight));

    setIsOutboundAncillariesAlertShown(shouldShowOutboundAlert);
  }, [groupedRows.type, missingAncillaries.outbound.length, outboundSsrAvailability, outboundFlight]);

  useEffect(() => {
    const shouldShowInboundAlert =
      groupedRows.type === AncillaryType.Sports &&
      (missingAncillaries.inbound.length > 0 || isAnyAncillaryUnavailable(inboundSsrAvailability, returnFlight));

    setIsInboundAncillariesAlertShown(shouldShowInboundAlert);
  }, [groupedRows.type, missingAncillaries.inbound.length, inboundSsrAvailability, returnFlight]);

  const isAnyAncillaryUnavailable = (flightSsrAvailability: Record<string, number>, flightOnd: string): boolean => {
    return (
      Object.values(flightSsrAvailability).some(count => count === 0) &&
      !isAncillaryIncludedForAllPassengers(flightSsrAvailability, ssrsToAdd, flightOnd)
    );
  };

  const missingAncillariesWarning = (
    <Alert severity="warning">
      * This Ancillarie(s) is no longer available for the chosen Flight. If you cancel Ancillarie(s) it might not be
      possible to book it again for the current Flight
    </Alert>
  );

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setDisplayIcon(!displayIcon)}
      >
        <Box className={classes.accordionSummaryContainer}>
          <Box className={classes.accordionHeader}>
            <Typography flex={1} className={classes.accordionInfo}>
              <SwitchComponent param={groupedRows.type} />
            </Typography>
            {displayIcon && (
              <Typography
                onClick={e => {
                  resetSsrs(groupedRows.type, setSsrsToAdd, groupedAncillary, includedSsrs);
                  setSectionCount(sectionCount + 1);
                  setSectionPrice(0);
                  e.stopPropagation();
                }}
                flex={1}
                className={classes.accordionRemove}
              >
                <TrashIcon />
                {intl.formatMessage({ id: 'ancillaries.edit.removeAll' })}
              </Typography>
            )}
          </Box>
          {sectionPrice > 0 && (
            <Typography className={classes.accordionSummaryPrice}>{sectionPrice.toFixed(2)} €</Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box className={classes.details}>
          <Checkbox
            key={groupedRows.type}
            className={classes.detailsCheckbox}
            onClick={() => setDisplayTable(!displayTable)}
            disabled={!isApplyPossible(groupedRows.matchedPassengers)}
          />
          <Typography className={classes.detailText}>
            {intl.formatMessage({ id: 'ancillaries.edit.applyForAll' })}
          </Typography>
        </Box>
        <br />
        <Box className={classes.passengerBox}>
          {passengers
            .filter(obj => obj.Type !== 'INF')
            .map((passenger, index) => {
              return (
                <Typography className={classes.passengerText} key={index}>
                  <strong style={{ fontWeight: 700 }}>{CreatePassengerAlias(passenger.Id)} - </strong>
                  {passenger.FirstName} {passenger.LastName} ({passenger.Type})
                </Typography>
              );
            })}
        </Box>
        <Divider className={classes.divider} />
        <br />
        {groupedRows.ancillaries.outbound.length === 0 ? (
          <AlertMessage message={{ type: 'error', msg: 'Ssrs Are Not Available For Outbound Flight!' }} />
        ) : (
          <>
            <FlightAncillaryTable
              key={'flight-1'}
              passengers={passengers}
              ancillaries={groupedRows.ancillaries.outbound}
              type={groupedRows.type}
              flightOnd={outboundFlight}
              isOutbound={true}
              addSsrsToAdd={addSsrsToAdd}
              removeSsrsToAdd={removeSsrsToAdd}
              findSsr={findSsr}
              updateSectionPrice={updateSectionPrice}
              displayTable={displayTable}
              ssrsToAdd={ssrsToAdd}
              rebooking={rebooking}
              fareFamily={outboundFare}
              setSsrsToAdd={setSsrsToAdd}
              includedSsrs={includedSsrs}
              missingAncillaries={missingAncillaries}
              ssrAvailability={outboundSsrAvailability}
              validatedAncillaries={validatedAncillaries}
              setSsrAvailability={setOutboundSsrAvailability}
              isFlightRebooked={isOutboundRebooked}
            />
            {isOutboundAncillariesAlertShown && missingAncillariesWarning}
            <br />
            {groupedRows.type === 'BAGGAGE' && isBaggageAddedOutbound && (
              <AdditionalLuggageTable
                key={'luggage-1'}
                passengers={passengers}
                ancillaries={groupedRows.ancillaries.outbound}
                type={groupedRows.type}
                flightOnd={outboundFlight}
                addSsrsToAdd={addSsrsToAdd}
                removeSsrsToAdd={removeSsrsToAdd}
                findSsr={findSsr}
                updateSectionPrice={updateSectionPrice}
                displayTable={displayTable}
                ssrsToAdd={ssrsToAdd}
                setLuggagePrice={setLuggagePrice}
                luggagePrice={luggagePrice}
                sectionCount={sectionCount}
                setSsrsToAdd={setSsrsToAdd}
              />
            )}
          </>
        )}
        {returnFlight && displayTable && (
          <>
            <Divider className={classes.divider} />
            <br />
            <FlightAncillaryTable
              key={'flight-2'}
              passengers={passengers}
              ancillaries={groupedRows.ancillaries.inbound}
              type={groupedRows.type}
              flightOnd={returnFlight}
              isOutbound={false}
              addSsrsToAdd={addSsrsToAdd}
              removeSsrsToAdd={removeSsrsToAdd}
              findSsr={findSsr}
              updateSectionPrice={updateSectionPrice}
              displayTable={displayTable}
              ssrsToAdd={ssrsToAdd}
              rebooking={rebooking}
              fareFamily={inboundFare}
              setSsrsToAdd={setSsrsToAdd}
              includedSsrs={includedSsrs}
              missingAncillaries={missingAncillaries}
              ssrAvailability={inboundSsrAvailability}
              validatedAncillaries={validatedAncillaries}
              setSsrAvailability={setInboundSsrAvailability}
              isFlightRebooked={isInboundRebooked}
            />
            {isInboundAncillariesAlertShown && missingAncillariesWarning}
            <br />
            {groupedRows.type === 'BAGGAGE' && isBaggageAddedReturn && (
              <AdditionalLuggageTable
                key={'luggage-2'}
                passengers={passengers}
                ancillaries={groupedRows.ancillaries.inbound}
                type={groupedRows.type}
                flightOnd={returnFlight}
                addSsrsToAdd={addSsrsToAdd}
                removeSsrsToAdd={removeSsrsToAdd}
                findSsr={findSsr}
                updateSectionPrice={updateSectionPrice}
                displayTable={displayTable}
                ssrsToAdd={ssrsToAdd}
                setLuggagePrice={setLuggagePrice}
                luggagePrice={luggagePrice}
                sectionCount={sectionCount}
                setSsrsToAdd={setSsrsToAdd}
              />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AncillaryAccordion;
